.footerWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0 0px 0;
}

.fMainSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   align-items: flex-start;
    padding: 20px 0;
}
.footerLogo{
    width: 200px;
}
.footerLinks{
    display: flex;
    /* width: 250px; */
    flex-direction: column;
   align-items: flex-start;
    justify-content: flex-start;
}
.footerLinks h3{
    color: var(--primary);
    font-size: 18px;
}
.footerLinks a{
    text-decoration: none;
    color: var(--ternary);
    margin: 5px 0 0 5px;
    font-size: 13px;
}

.copyright{
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.copyright p{
    font-size: 14px; 
    text-align: center;
    justify-content: center;
}
.copyright p>a{
    color: var(--primary);
    font-weight: bold;

}

.socialCards{
    width: 100%;
    display: flex;
    flex-direction: row;
   align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0;
}
.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.row a{
    display: flex;
    align-items: center;
    color: var(--ternary);

}
.row a>svg{
    margin-right: 10px;
}
.payment{
    margin-left: 10px;
}

#instagramLink{
    margin-left: 20px;
}
.break{
    display: none;
}

@media screen and (max-width:650px) {
    .fMainSection{
        flex-direction: column;
        align-items: center;
    }
    .footerLinks{
        margin-top: 20px;
        align-items: center;
    }
    .footerLinks a{
        margin: 5px 0 0 0;
    }
    .socialCards{
        flex-direction: column;
        align-items: center;
    }
   
    .socialCards .row:nth-child(2){
        
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 5px;
    }
    .socialCards .row:nth-child(1){
        
      
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 5px;
    }
    #instagramLink{
        margin: 0;
    }
    .payment{
        margin-left: 0px;
    }
}

@media screen and (max-width:400px) {
    .break{
        display: block;
    }
    
}