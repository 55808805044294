:root {
  --primary: #c3854c;
  --secondary: #ffffff;
  --ternary: #434040;
}
* {
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Sarabun", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background-color: rgba(195, 133, 76, 0.1);
}
.wrapper {
  max-width: 1250px;
  width: 95%;
  margin: 0 auto;
}

.hoverable {
  transition: all 0.1s ease-in;
  cursor: pointer;
}
.hoverable:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in;
  cursor: pointer;
}
a {
  text-decoration: none;
}

.newsletterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  text-align: center;
}
.newsletterWrapper h1 {
  font-size: 25px;
  color: var(--primary);
}
.newsletterWrapper p {
  font-size: 18px;
  margin-top: 30px;
}
.newsletterInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  margin-top: 50px;
  width: 50%;
}
.emailInput {
  width: calc(75% - 10px);
  height: calc(100% - 1px);
  outline: none;
  border: 1px solid var(--ternary);
  padding: 0 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.newsletterBtn {
  width: 25%;
  height: 100%;
  outline: none;
  border: none;
  color: var(--secondary);
  background-color: var(--primary);
  font-weight: bold;
  cursor: pointer;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.newsletterBtn:disabled {
  background-color: grey;
}

.statesOverlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.statesOverlay h1 {
  color: white;
  text-align: center;
  font-size: 20px;
  margin-top: 100px;
}
.statesOverlay select {
  margin-top: 20px;
  padding: 5px 0;
  outline: none;
  border: 1px solid var(--primary);
  border-radius: 2px;
  color: var(--primary);
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 100px auto;
}
.infoWrapper h2 {
  margin-top: 50px;
}
.infoWrapper p {
  margin-top: 25px;
}

.selectState {
  width: 315px;
  margin-top: 20px;
}

p,
label {
  color: var(--ternary);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader img {
  width: 300px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media screen and (max-width: 850px) {
  .newsletterWrapper {
    padding: 50px 0;
  }
}
@media screen and (max-width: 750px) {
  .newsletterInput {
    width: 100%;
  }
  .emailInput {
    width: 70%;
  }
  .newsletterBtn {
    width: 30%;
  }
}
