.searchWrapper {
  width: 90%;
  height: 50vh;
  margin: 0 auto;
  z-index: 99999;
  position: absolute;
  background-color: white;
  left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
}
.searchCloseIcon {
  position: absolute;
  left: 0;
  cursor: pointer;
}
.searchProducts {
  width: 97%;
  margin-top: 20px;
  height: calc(50vh - 10vh);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.searchProduct {
  width: 98%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--primary);
  border-radius: 2px;
  overflow: hidden;
}
.searchProduct img {
  width: 50px;
  padding-left: 5px;
}
.searchProduct h1 {
  font-size: 20px;
  padding-left: 10px;
  color: #434040;
}
.searchProduct a {
  padding-right: 10px;
  font-size: 14px;
}

@media screen and (max-width: 650px) {
  .searchProducts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .searchProducts {
    grid-template-columns: repeat(1, 1fr);
  }
}
