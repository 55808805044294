.productWrapper {
  /* height: 80vh; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 50px auto;
  justify-content: space-between;
}
.productSwiper {
  display: block;
  width: 50%;
}
.productInfo {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.productInfo h1 {
  color: var(--primary);
}
.productInfo h3 {
  margin-top: 10px;
}
.productInfo button {
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
  color: white;
  background-color: var(--primary);
  outline: none;
  border: none;
  border-radius: 2px;
}
.productInfo p {
  margin-top: 10px;
}
.productInfo h4 {
  margin-top: 10px;
  color: var(--primary);
}

.facebookShareBtn {
  width: fit-content !important;
  background-color: #0f91f3 !important;
  padding: 5px 20px !important;
  color: var(--secondary) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productSlider {
  height: 80vh;
}

@media screen and (max-width: 750px) {
  .productWrapper {
    flex-direction: column;
  }
  .productSlider {
    width: 70%;
  }
  .productSwiper {
    width: 100%;
  }
  .productInfo {
    width: 100%;
    margin-top: 20px;
  }
  .productInfo h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 450px) {
  .productSlider {
    width: 100%;
  }
}
