.cartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0;
}

.cartWrapper h1 {
  color: var(--primary);
}

.cartProducts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.cartRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  border-bottom: 1px solid var(--primary);
  padding: 20px 0;
  position: relative;
}

.cartRow1 {
  border-bottom: 1px solid var(--primary);
}
.productCart {
  display: flex;
  align-items: center;
  color: #434040;
}
.productCart h4 {
  padding-left: 20px;
}
.productCart img {
  width: 150px;
}
.removeBtn {
  position: absolute;
  right: 0;
  bottom: 20px;
  cursor: pointer;
}
.removeBtn:hover {
  color: red;
}
.shopLink {
  margin-top: 20px;
  padding: 5px 20px;
  background-color: var(--primary);
  align-items: center;
  display: flex;
  color: white;
  width: fit-content;
  margin: 20px auto;
}
.emptyCart {
  margin-top: 30px;
}
.buttonRow {
  justify-content: end;
}
.buttonRow button {
  outline: non;
  padding: 5px 2px;
  background-color: var(--primary);
  border: none;
  color: white;
}
