.categoriesWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 0;

}
.categoriesTitle{
    font-size: 40px;
    color: var(--primary);
}
.categoriesGrid{
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:15px;
}
.categoriesCard{
    width: 100%;
    padding-top: 100%;
    background-color: var(--primary);
    position: relative;
    text-align: center;
    background-position: center;
    background-size: cover;
    border-radius: 2px;
}
.categoriesCard::before {
    content: "";
    display: block;
  }

  .categoriesCard > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .categoriesCardTitle {
    color: var(--secondary);
  }

  .categoryImage{
    width: 100%;
    height: 40vh;
    background-color: red;
    margin: 30px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .categoryImage div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .categoryImage h1{
    color: var(--secondary);
  }

  @media screen and (max-width:750px) {

    .categoriesGrid{
    
    grid-template-columns: repeat(2,1fr);
   
}
  }


  @media screen and (max-width:550px) {

    .categoriesGrid{
    
    grid-template-columns: repeat(1,1fr);
   
}
  }