.productsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0;
}

.productsTitle {
  font-size: 40px;
  color: var(--primary);
}
.productsGrid {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.productsCard {
  width: 100%;
  padding-top: 150%;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 2px;
}
.productsCard::before {
  content: "";
  display: block;
}

.productInfos {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 20%;
  /* background-color: rgba(0, 0, 0, 0.2); */
}
.productCardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.productsCardTitle {
  /* padding: 0 0 20px 20px ; */
  color: var(--primary);
}

.productsCardPrice {
  color: var(--ternary);
  /* padding: 0 0 20px 20px; */
}
.filtersWrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
}
.categoriesFilter {
  display: flex;
  align-items: center;
}
.categoriesFilter label {
  color: var(--primary);
}
.categoriesFilter select {
  outline: none;
  margin-left: 20px;
  padding: 10px 0;
  border: 1px solid var(--primary);
  border-radius: 2px;
  color: var(--ternary);
}
.filtersWrapper .categoriesFilter:nth-child(3) {
  margin-left: 20px;
}
.filtersWrapper .categoriesFilter:nth-child(2) {
  margin-left: 20px;
}
.categoriesFilter select > optgroup {
  color: var(--primary);
}
.categoriesFilter select optgroup > option {
  color: var(--ternary);
}

@media screen and (max-width: 750px) {
  .productsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .filtersWrapper {
    flex-direction: column;
  }
  .filtersWrapper .categoriesFilter:nth-child(2) {
    margin-left: 0px;
    margin-top: 20px;
  }
  .filtersWrapper .categoriesFilter:nth-child(3) {
    margin-left: 0px;
    margin-top: 20px;
  }
  .categoriesFilter {
    width: 100%;
    justify-content: space-between;
  }
  .filtersWrapper {
    width: fit-content;
    margin: 0 auto 0 0;
  }
  .productsCardTitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .productsGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .categoriesFilter {
    flex-direction: column;
    align-items: flex-start;
  }
  .categoriesFilter select {
    margin: 20px 0 0 0;
  }
}
