.aboutWrapper {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.aboutWrapper h1 {
  color: var(--primary);
}
.aboutWrapper p {
  margin-top: 30px;
  text-align: left;
  max-width: 70%;
  margin: 30px auto 0;
}
.aboutImage {
  width: 100%;
  height: 70vh;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 750px) {
  .aboutWrapper p {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .aboutImage {
    height: 30vh;
  }
}
