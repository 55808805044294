.navbarWrapper {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
  z-index: 111111;
}
#navbarLogo {
  width: 100px;
}
#navbarLogo img {
  width: 100%;
}
.navLinks {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
}
.navLinks a {
  margin: 0 10%;
  color: var(--ternary);
  font-size: 20px;
}
.navLinks a:hover {
  text-decoration: underline;
}
.navIcons {
  display: flex;
  align-items: center;
}
.navIcons a {
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.location {
  text-decoration: underline !important;
}
.mobileIcon {
  display: none;
}
.mobileNavbar {
  position: absolute;
  width: 108%;
  height: 100vh;
  /* padding: 20px; */
  top: 0;
  left: -5%;
  background-color: var(--ternary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 999999;
}
.cartLink {
  position: relative;
}
.countLabel {
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: grey;
  padding: 1px 8px;
  border-radius: 100%;
  color: white;
}
@media screen and (max-width: 998px) {
  .desktopIcon {
    display: none;
  }
  .navLinks {
    display: none;
  }
  .mobileIcon {
    display: block;
  }
  .closeIcon {
    width: 95%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .mobileNavLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 40px;
    margin-top: 30px;
  }
  .mobileNavLinks a {
    color: var(--secondary);
    margin: 20px 0 0 0;
    font-size: 25px;
  }
  .mobileNavLinks svg {
    padding: 0;
  }

  #mobileNavbarLogo {
    padding-left: 40px;
    margin-top: 50px;
    width: 100px;
  }
  #mobileNavbarLogo img {
    width: 100%;
  }
}
