.homepageSwiper {
  height: calc(100vh - 65px);
}
.slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hSsectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}
.hSsectionWrapper img {
  width: 48%;
  margin-right: 20px;
}

.hSsectionText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
  margin-left: 20px;
}
.hSsectionText h1 {
  font-size: 25px;
  text-transform: uppercase;
  color: var(--primary);
}
.hSsectionText p {
  margin-top: 20px;
  font-size: 18px;
  color: var(--ternary);
}
.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
}

.swiper-button-prev > svg,
.swiper-button-next > svg {
  color: #c3854c !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: rgba(195, 133, 76, 0.6);
  border-radius: 50%;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary);
  opacity: 1;
}

.hTsectionWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hTsectionWrapper h1 {
  font-size: 5rem;
  color: var(--secondary);
}
.hTsectionWrapper h3 {
  font-size: 2.5rem;
  color: var(--secondary);
  margin: 20px 0;
}
.hTsectionWrapper a {
  font-size: 1.5rem;
  color: var(--secondary);
  padding: 10px 18px;
  background-color: var(--primary);
  border-radius: 2px;
}
@media screen and (max-width: 850px) {
  .hSsectionWrapper {
    flex-direction: column;
  }
  .hSsectionWrapper img {
    width: 80%;
    margin-right: 0;
  }
  .hSsectionText {
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 750px) {
  .homepageSwiper {
    height: calc(75vh - 65px);
  }
  .hSsectionText h1 {
    font-size: 20px;
  }
  .hSsectionText p {
    margin-top: 20px;
    font-size: 16px;
  }
  .hSsectionWrapper img {
    width: 100%;
  }
  .hTsectionWrapper h1 {
    font-size: 3rem;
  }
  .hTsectionWrapper h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 500px) {
  .homepageSwiper {
    height: calc(50vh - 65px);
  }
  .hTsectionWrapper h1 {
    font-size: 2rem;
  }
  .hTsectionWrapper h3 {
    font-size: 1rem;
  }
}
