.contactWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.contactWrapper h1 {
  color: var(--primary);
}

.contactWrapper p {
  max-width: 750px;
  width: 100%;
  margin: 30px auto 0;
  text-align: center;
}

.contact {
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.contact form {
  width: 50%;
}

.contactInfo {
  width: 40%;
}
.contactInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.contactInputs label {
  font-size: 15px;
}

.contactInputs input {
  width: calc(100% - 5px);
  height: 30px;
  outline: none;
  padding: 0 0 0 5px;
  margin-top: 10px;
  border: 1px solid var(--primary);
  border-radius: 2px;
}

.contactInputs textarea {
  width: calc(100% - 5px);
  margin-top: 10px;
  height: 100px;
  resize: none;
  outline: none;
  padding: 5px 0 5px 5px;
  border: 1px solid var(--primary);
  border-radius: 2px;
}

.sendBtn {
  margin-top: 20px;
  padding: 5px 30px;
  border: 1px solid var(--primary);
  border-radius: 2px;
  background-color: var(--primary);
  outline: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.sendBtn:disabled {
  background-color: grey;
}

.contactInputs p,
.contactInputs h3 {
  text-align: left;
}
.contactInputs h3 {
  color: var(--primary);
  margin-top: 5px;
}

@media screen and (max-width: 750px) {
  .contact {
    flex-direction: column;
    align-items: center;
  }
  .contact form,
  .contactInfo {
    width: 100%;
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .contactInfos {
    width: 200px;
  }
}
